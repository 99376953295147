body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(11, 12, 48);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about {
  margin-top: 50px;
  margin-bottom: 400px;
}

h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: left;
}

p {
  font-size: 16px;
  line-height: 1.6;
}
.projects {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 70px;
  text-align: left;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dbe5e8; /* Change this to your preferred background color */
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.logo img {
  width: 200px;
  height: auto;
}

.nav-links {
  font-size: 18px;
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: rgb(11, 12, 48); /* Change link color as needed */
}

/* Add hover effect for links if desired */
.nav-links a:hover {
  font-weight: bold;
}

.project {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.project-details {
  flex: 1;
  text-align: left;
}

.project-details h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.project-details p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
}

.technologies span {
  background-color: #c6deeb;
  color: #555555;
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.gallery {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  width: calc((100% - 30px) / 3); /* Adjust this to change the number of images per row */
  border-radius: 8px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.resume {
  padding: 50px 0;
}

.resume-content {
  margin-bottom: 40px;
}

h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.job {
  margin-bottom: 40px;
}

.job h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.job p {
  font-size: 16px;
  margin-bottom: 15px;
}

.art-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.artwork {
  text-align: left;
}

.artwork img {
  max-width: 100%;
  height: auto;
}

.artwork p {
  margin-top: 0px;
  font-weight: bold;
}

/* styles.css */
.icon-container {
  display: flex;
  align-items: flex-start; /* Align items at the top of the flex container */
  gap: 20px; /* Add spacing between icons */
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-item a {
  display: block;
  text-decoration: none;
  color: #333;
}

.icon-item img {
  width: 70px;
  height: auto;
  margin-bottom: 5px;
}



